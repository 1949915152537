<template>
  <div id="app">
    <!-- Entering, reload site transition -->
    <transition name="fade" mode="out-in">
      <div class="app-wrapper" v-if="!loadingScreen" key="content">
        <!-- Header -->
        <flexi-header
          :inputData="inputData.navigationPages"
          :toggleTheme="toggleTheme"
          :changeLanguage="setLanguage"
        >
          <template v-slot:logo>
            <img
              :src="require('./assets/images/logo.png')"
              @click="goToRoute('/')"
            />
            <span :style="language === 'АЛ' || language ===  'AL' ? 'max-width: 240px' : 'max-width: 205px'" class="logo-text" v-text="language === 'MK' || language === 'МК' ? 'Државен инспекторат за животна средина' : 'INSPEKTORATI SHTETEROR PER MJEDISIN JETESOR'" />
          </template>
          <template
            v-slot:navigation="{ inputData, toggleTheme, changeLanguage }"
          >
            <span v-if="!isMobile" class="flexi-header__wrapper--text"></span>
            <flexi-desktop-navigation :navigation="inputData">
              <!-- Desktop Version -->
              <template v-slot:navigation v-if="!isMobile">
                <template>
                  <a
                    class="flexi-desktop-navigation__wrapper--item"
                    v-for="(value, key) in inputData"
                    :key="key"
                    :id="key"
                    @click="
                      value.isLink
                        ? navigateTo(value.href)
                        : changeTableAndScroll(value)
                    "
                    @mouseover="openDropdown(key)"
                    @mouseleave="showDropDown = false"
                  >
                    <li v-text="value.name" />
                  </a>
                  <div
                    v-if="language === 'МК' || language === 'MK'"
                    class="content--dropdownmenu"
                    @mouseover="showDropDown = true"
                    @mouseleave="showDropDown = false"
                  >
                    <span v-if="showDropDown" class="arrow-up" />
                    <a
                      v-show="showDropDown"
                      class="flexi-desktop-navigation__wrapper--dropdownmenu"
                      v-for="(value, key) in dropDownNavMK"
                      :key="key"
                    >
                      <li
                        v-text="value.name"
                        @click="
                          value.isLink
                            ? navigateTo(value.href)
                            : changeTableAndScroll(value)
                        "
                      />
                    </a>
                  </div>

                  <div
                    v-else
                    class="content--dropdownmenu"
                    @mouseover="showDropDown = true"
                    @mouseleave="showDropDown = false"
                  >
                    <span v-if="showDropDown" class="arrow-up" />
                    <a
                      v-show="showDropDown"
                      class="flexi-desktop-navigation__wrapper--dropdownmenu"
                      v-for="(value, key) in dropDownNavAL"
                      :key="key"
                    >
                      <li
                        v-text="value.name"
                        @click="
                          value.isLink
                            ? navigateTo(value.href)
                            : changeTableAndScroll(value)
                        "
                      />
                    </a>
                  </div>
                </template>
              </template>

              <!-- Mobile version -->
              <template v-slot:navigation v-else>
                <template>
                  <a class="flexi-desktop-navigation__wrapper--item">
                    <span :style="language === 'АЛ' || language ===  'AL' ? 'max-width: 240px' : 'max-width: 205px'" class="logo-text" v-text="language === 'MK' || language === 'МК' ? 'Државен инспекторат за животна средина' : 'INSPEKTORATI SHTETEROR PER MJEDISIN JETESOR'" />
                  </a>
                  <div class="flexi-desktop-navigation__wrapper--bottom">
                    <a
                      v-for="(value, key) in inputData"
                      :key="key"
                      @click="
                        value.isLink
                          ? navigateTo(value.href)
                          : changeTableAndScroll(value)
                      "
                    >
                      <li v-text="value.name" />
                    </a>
                    <div
                      v-if="language === 'МК' || language === 'MK'"
                      v-show="showDropDown"
                      class="content--dropdownmenu"
                    >
                      <span class="arrow-up" />
                      <a
                        class="flexi-desktop-navigation__wrapper--dropdownmenu"
                        v-for="(value, key) in dropDownNavMK"
                        :key="key"
                      >
                        <li
                          v-text="value.name"
                          @click="
                            value.isLink
                              ? navigateTo(value.href)
                              : changeTableAndScroll(value)
                          "
                        />
                      </a>
                    </div>
                    <div
                      v-else
                      v-show="showDropDown"
                      class="content--dropdownmenu"
                    >
                      <span class="arrow-up" />
                      <a
                        v-show="showDropDown"
                        class="flexi-desktop-navigation__wrapper--dropdownmenu"
                        v-for="(value, key) in dropDownNavAL"
                        :key="key"
                      >
                        <li
                          v-text="value.name"
                          @click="
                            value.isLink
                              ? navigateTo(value.href)
                              : changeTableAndScroll(value)
                          "
                        />
                      </a>
                    </div>
                  </div>
                </template>
              </template>
            </flexi-desktop-navigation>
            <flexi-theme-switcher :toggleTheme="toggleTheme" />
            <flexi-language-switcher
              :languages="
                language === 'MK' || language === 'МК'
                  ? languagesItemsMk
                  : languagesItems
              "
              :changeLanguage="changeLanguage"
            />
          </template>
        </flexi-header>
        <!-- Router View + Transition -->
        <vue-page-transition name="overlay-up-down">
          <router-view />
        </vue-page-transition>
        <!-- Footer -->
        <flexi-footer :year="year">
          <template v-slot:footer>
            <span class="footer__bottom" v-if="language === 'МК' || language === 'MK'">
              © {{ year }} ИКС /
              <a class="action" href="https://iks.edu.mk/" target="_blank">
                Институт за комуникациски студии
              </a>
              / Designed and powered by
              <a class="action" href="https://akrinum.com" target="_blank">
                Akrinum OÜ
              </a>
            </span>
            <span v-else class="footer__bottom">
              © {{ year }} ISK /
              <a class="action" href="https://iks.edu.mk/" target="_blank">
                Instituti për Studime të Komunikimit
              </a>
              / Designed and powered by
              <a class="action" href="https://akrinum.com" target="_blank">
                Akrinum OÜ
              </a>
            </span>
          </template>
        </flexi-footer>
      </div>
      <!-- Spinner on enterin or reload -->
      <flexi-splash v-else key="content2">
        <template v-slot:spinner>
          <semipolar-spinner
            :animation-duration="2000"
            :size="105"
            color="#ffffff"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-duration="700"
            data-aos-easing="ease-in-out"
            data-aos-mirror="true"
            data-aos-once="false"
            data-aos-anchor-placement="top-center"
          />
        </template>
      </flexi-splash>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { dropDownNavMK, dropDownNavAL } from "./static/data/dropDownNav";
import FlexiDesktopNavigation from "./components/FlexiDesktopNavigation.vue";
import FlexiHeader from "./components/FlexiHeader.vue";

const coordinatesTypes = {
  A_DOZVOLI: "A Дозволи",
  B_DOZVOLI: "Б Дозволи",
  ELABORATI: "Елаборати"
};

const expandOnItemMK = 3;
const expandOnItemAL = 3;

export default {
  components: {
    FlexiDesktopNavigation,
    FlexiHeader
  },
  watch: {
    theme: {
      handler(theme) {
        const header = document.querySelector(".flexi-header__wrapper");
        const menu = document.querySelector(".content--dropdownmenu");

        if (this.pageYOffset > 425) {
          if (theme === "") {
            header.style.backgroundColor = "#236475";
            menu.style.backgroundColor = "#236475";
          } else {
            header.style.backgroundColor = "#155185";
            menu.style.backgroundColor = "#155185";
          }
        } else {
          Array.prototype.filter.call(header, function(header) {
            header.style.backgroundColor = "transparent";
            menu.style.backgroundColor = "transparent";
          });
        }
      },
      immediate: false
    }
  },
  computed: {
    ...mapGetters([
      "coordinatesData",
      "inputData",
      "loadingScreen",
      "year",
      "theme",
      "language",
      "selectedCoordinatesType"
    ])
  },
  data() {
    return {
      dropDownNavMK: dropDownNavMK,
      dropDownNavAL: dropDownNavAL,
      formatDate: "DD/MM/YYYY",
      languagesItems: [
        {
          title: "MK"
        },
        {
          title: "AL"
        }
      ],
      languagesItemsMk: [
        {
          title: "МК"
        },
        {
          title: "АЛ"
        }
      ],
      pageYOffset: 0,
      showDropDown: false
    };
  },
  methods: {
    ...mapActions([
      "fetchCMSData",
      "fetchAPIData",
      "setYear",
      "toggleTheme",
      "setLanguage",
      "setSelectedItem",
      "setSelectedCoordinatesType",
      "setTable"
    ]),
    changeTableAndScroll(item) {
      if (item.name === "А Дозволи" || item.name === "Б Дозволи" || item.name === 'A Licenca' || item.name === 'B Licenca') {
        this.setTable(item.name);
        this.smoothScroll(item.href);
        this.showDropDown = false;
      } else if (
        (item.name === "Елаборати МЖСПП" ||
          item.name === "Елаборати Општини" ||
          item.name === 'Komuna Elaborate' ||
          item.name === 'Elaborate MMJPH') &&
        this.currentResolution < 990
      ) {
        if (this.showDropDown) {
          if (this.language === 'МК' || this.language === 'MK') {
            this.setTable("Елаборати");
            this.smoothScroll(item.href);

            setTimeout(() => {
              this.showDropDown = false;
            }, 100);
          } else {
            this.setTable("Elaborate");
            this.smoothScroll(item.href);

            setTimeout(() => {
              this.showDropDown = false;
            }, 100);
          }
        }
        this.showDropDown = true;
      } else if (item.name === "Елаборати Општини" || item.name === 'Komuna Elaborate') {
        if (this.language === 'МК' || this.language === 'MK') {
          this.setTable("Елаборати");
        } else {
          this.setTable("Elaborate");
        }
        this.smoothScroll(item.href);
        this.showDropDown = false;
      } else if (item.name === "Елаборати МЖСПП" || item.name === "Elaborate MMJPH") {
        if (this.lanugage === 'MK' || this.language === 'МК') {
          this.setTable("Елаборати МЖСПП");
        } else {
          this.setTable("Elaborate MMJPH");
        }
          this.smoothScroll(item.href);
          this.showDropDown = false;
      } else {
        this.smoothScroll(item.href);
        this.showDropDown = false;
      }
      this.smoothScroll(item.href);
    },
    navigateTo(url) {
      window.open(url, "_blank");
    },
    onLoad() {
      if (this.currentResolution < 990) {
        const menu = document.querySelector(".content--dropdownmenu");
        const navMK = document.querySelector(
          ".flexi-desktop-navigation__wrapper--bottom" +
            " :nth-child(" +
            (expandOnItemMK + 1) +
            ")"
        );
        const rectMK = navMK.getBoundingClientRect();
        const navAL = document.querySelector(
          ".flexi-desktop-navigation__wrapper--bottom" +
            " :nth-child(" +
            (expandOnItemAL + 1) +
            ")"
        );
        const rectAL = navAL.getBoundingClientRect();

        if (this.language === "МК" || this.language === "MK") {
          menu.style.left = rectMK.left - 30 + "px";
        } else {
          menu.style.left = rectAL.left - 30 + "px";
        }

        if (this.currentResolution < 533) {
          menu.style.top = rectMK.top - 13 + "px";
          menu.style.top = rectAL.top - 13 + "px";
        } else if (this.currentResolution > 534) {
          menu.style.top = "17px";
        }
      }
    },
    onScroll() {
      const header = document.getElementsByClassName("flexi-header__wrapper");
      const menu = document.querySelector(".content--dropdownmenu");
      var vm = this;
      this.pageYOffset = window.pageYOffset;

      if (window.pageYOffset > 425) {
        vm.pageYOffset = window.pageYOffset;
        Array.prototype.filter.call(header, function(header) {
          if (vm.theme === "") {
            header.style.backgroundColor = "#236475";
            menu.style.backgroundColor = "#236475";
          } else {
            header.style.backgroundColor = "#155185";
            menu.style.backgroundColor = "#155185";
          }

          header.style.height = "56px";
        });
      } else {
        Array.prototype.filter.call(header, function(header) {
          header.style.backgroundColor = "transparent";
          menu.style.backgroundColor = "transparent";
          header.style.height = "86px";
        });
      }
    },
    openDropdown(id) {
      const menu = document.querySelector(".content--dropdownmenu");

      if (
        this.language === "МК" ||
        (this.language === "MK" && this.currentResolution > 990)
      ) {
        if (id === expandOnItemMK) {
          const elem = document.getElementById(id);
          const rect = elem.getBoundingClientRect();
          menu.style.left = rect.left - 20 + "px";
          this.showDropDown = true;
        }
      } else if (
        this.language === "АЛ" ||
        (this.language === "AL" && this.currentResolution > 990)
      ) {
        if (id === expandOnItemAL) {
          const elem = document.getElementById(id);
          const rect = elem.getBoundingClientRect();
          menu.style.left = rect.left - 20 + "px";
          this.showDropDown = true;
        }
      }
    }
  },
  beforeMount() {
    setTimeout(() => {
      this.onLoad();
    }, 4000);
    window.addEventListener("resize", this.onLoad);
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onLoad);
    window.removeEventListener("scroll", this.onScroll);
  },
  async mounted() {
    this.fetchCMSData();
    await this.fetchAPIData();
    this.setYear();
    this.setSelectedItem(this.coordinatesData.a_dozvoli);
    this.setSelectedCoordinatesType(coordinatesTypes.A_DOZVOLI);
  }
};
</script>
<style lang="scss">
@import "assets/scss/application";
.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom,
.overlay-up-down {
  z-index: 10003 !important;
}
</style>
