<template>
  <div class="flexi-container">
    <!-- Top Card  -->
    <flexi-top-card
      v-if="inputData.homePage"
      :inputData="inputData.homePage.top_card"
      @button-clicked="smoothScroll"
    >
      <template v-slot:content>
        <div class="scrollDist"></div>
        <div class="main">
          <svg viewBox="0 0 1200 800" xmlns="http://www.w3.org/2000/svg">
            <mask id="m">
              <g class="cloud1">
                <rect fill="#fff" width="100%" height="801" y="799" />
                <image
                  xlink:href="https://assets.codepen.io/721952/cloud1Mask.jpg"
                  width="1200"
                  height="800"
                />
              </g>
            </mask>

            <image
              class="sky"
              :xlink:href="
                theme === ''
                  ? 'https://app.imgforce.com/images/user/x5T_1648688017_last-card-green.png'
                  : 'https://app.imgforce.com/images/user/smH_1648688273_Slika-PNG%20(1).png'
              "
              width="1200"
              height="860"
            />
            <!-- <image
              class="mountBg"
              xlink:href="https://assets.codepen.io/721952/mountBg.png"
              width="1200"
              height="800"
            /> -->
            <!-- <image
              class="mountMg"
              xlink:href="https://assets.codepen.io/721952/mountMg.png"
              width="1200"
              height="800"
            /> -->
            <image
              class="cloud2"
              xlink:href="https://assets.codepen.io/721952/cloud2.png"
              width="1200"
              height="800"
            />
            <!-- <image
              class="mountFg"
              xlink:href="https://assets.codepen.io/721952/mountFg.png"
              width="1200"
              height="800"
            /> -->
            <image
              class="cloud1"
              xlink:href="https://assets.codepen.io/721952/cloud1.png"
              width="1200"
              height="800"
            />
            <image
              class="cloud3"
              xlink:href="https://assets.codepen.io/721952/cloud3.png"
              width="1200"
              height="800"
            />
            <text
              fill="#fff"
              x="380"
              y="200"
              v-text="inputData.homePage.top_card.title"
            />
            <text
              fill="#fff"
              x="420"
              y="220"
              v-text="inputData.homePage.top_card.subTitle"
            />
            <polyline
              v-if="!isMobile"
              class="arrow"
              fill="#fff"
              points="599,250 599,289 590,279 590,282 600,292 610,282 610,279 601,289 601,250"
            />

            <g mask="url(#m)">
              <rect fill="#fff" width="100%" height="100%" />
            </g>

            <rect
              @click="smoothScroll('about')"
              id="arrowBtn"
              width="100"
              height="100"
              opacity="0"
              x="550"
              y="220"
              style="cursor: pointer"
            />
          </svg>
        </div>
      </template>
    </flexi-top-card>

    <!-- About -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[0]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <div
          class="header__description"
          v-html="slotProps.inputData.description"
        />
      </template>
    </flexi-wrapper>

    <!-- Datoteka / Slider -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[1]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content>
        <div class="app-wrap">
          <div class="app">
            <div class="cardList">
              <button class="cardList__btn btn btn--left">
                <div class="icon">
                  <svg>
                    <use xlink:href="#arrow-left"></use>
                  </svg>
                </div>
              </button>

              <div class="cards__wrapper">
                <div
                  class="card current--card"
                  @click="language === 'MK' || language === 'МК' ? selectTableAndScroll('А Дозволи') : selectTableAndScroll('A Licenca')"
                >
                  <div class="card__image">
                    <img src="https://source.unsplash.com/Z8dtTatMVMw" alt="" />
                  </div>
                </div>

                <div
                  class="card next--card"
                  @click="language === 'MK' || language === 'МК' ? selectTableAndScroll('Б Дозволи') : selectTableAndScroll('B Licenca')"
                >
                  <div class="card__image">
                    <img
                      src="https://app.imgforce.com/images/user/mLE_1647039889_movin.jpg"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="card previous--card"
                  @click="language === 'MK' || language === 'МК' ? selectTableAndScroll('Елаборати МЖСПП') : selectTableAndScroll('Elaborate MMJPH')"
                >
                  <div class="card__image">
                    <img
                      src="https://app.imgforce.com/images/user/rxq_1647040047_istockphoto-1155511952-612x612.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>

              <button class="cardList__btn btn btn--right">
                <div class="icon">
                  <svg>
                    <use xlink:href="#arrow-right"></use>
                  </svg>
                </div>
              </button>
            </div>

            <div class="infoList">
              <div class="info__wrapper">
                <div class="info current--info">
                  <h1
                    class="text name"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Еколошки дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Licenca ekologjike'
                        : language === 'EN' || language === 'АН'
                        ? 'Еколошки дозволи EN'
                        : 'Licenca ekologjike'
                    "
                  />
                  <h4
                    class="text location"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'А-дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'A licenca'
                        : language === 'EN' || language === 'АН'
                        ? 'А-дозволи EN'
                        : 'A licenca'
                    "
                  />
                  <p
                    class="text description"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'А-дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'A licenca'
                        : language === 'EN' || language === 'АН'
                        ? 'А-дозволи EN'
                        : 'A licenca'
                    "
                  />
                </div>

                <div class="info next--info">
                  <h1
                    class="text name"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Еколошки дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Licenca ekologjike'
                        : language === 'EN' || language === 'АН'
                        ? 'Еколошки дозволи EN'
                        : 'Licenca ekologjike'
                    "
                  />
                  <h4
                    class="text location"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Б-дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'B licenca'
                        : language === 'EN' || language === 'АН'
                        ? 'Б-дозволи EN'
                        : 'B licenca'
                    "
                  />
                  <p
                    class="text description"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Б-дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'B licenca'
                        : language === 'EN' || language === 'АН'
                        ? 'Б-дозволи EN'
                        : 'B licenca'
                    "
                  />
                </div>

                <div class="info previous--info">
                  <h1
                    class="text name"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Елаборати'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Elaborate'
                        : language === 'EN' || language === 'АН'
                        ? 'Елаборати EN'
                        : 'Elaborate'
                    "
                  />
                  <h4
                    class="text location"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Издадени од МЖСПП'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Elaborate MMJPH'
                        : language === 'EN' || language === 'АН'
                        ? 'Издадени од МЖСПП EN'
                        : 'Elaborate MMJPH'
                    "
                  />
                  <p
                    class="text description"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Издадени од МЖСПП'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Elaborate MMJPH'
                        : language === 'EN' || language === 'АН'
                        ? 'Издадени од МЖСПП EN'
                        : 'Elaborate MMJPH'
                    "
                  />
                </div>
              </div>
            </div>

            <div class="app__bg">
              <div class="app__bg__image current--image">
                <img src="https://source.unsplash.com/Z8dtTatMVMw" alt="" />
              </div>
              <div class="app__bg__image next--image">
                <img src="https://source.unsplash.com/9dmycbFE7mQ" alt="" />
              </div>
              <div class="app__bg__image previous--image">
                <img src="https://source.unsplash.com/m7K4KzL5aQ8" alt="" />
              </div>
            </div>
          </div>

          <svg class="icons" style="display: none">
            <symbol
              id="arrow-left"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <polyline
                points="328 112 184 256 328 400"
                style="
                  fill: none;
                  stroke: #fff;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 48px;
                "
              />
            </symbol>
            <symbol
              id="arrow-right"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <polyline
                points="184 112 328 256 184 400"
                style="
                  fill: none;
                  stroke: #fff;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 48px;
                "
              />
            </symbol>
          </svg>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Podatoci / Tables -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[2]"
    >
      <template v-slot:content>
        <div class="selector-wrapper selector-wrapper__header">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="table" size="1x" />
            <label
              class="title"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Избери табела'
                  : language === 'AL' || language === 'АЛ'
                  ? 'Zgjidhni një tabelë'
                  : language === 'EN' || language === 'АН'
                  ? 'Select a table'
                  : 'Zgjidhni një tabelë'
              "
            />
          </div>
          <div
            class="selector-wrapper__button"
            :class="{
              active: selectedTable === 'А Дозволи' || selectedTable === 'A Licenca'
            }"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'А Дозволи'
                : language === 'AL' || language === 'АЛ'
                ? 'A licenca'
                : language === 'EN' || language === 'АН'
                ? 'А Дозволи EN'
                : 'A licenca'
            "
            @click="language === 'МК' || language === 'MK' ? changeSelectedTable('А Дозволи') : changeSelectedTable('A Licenca')"
          />
          <div
            class="selector-wrapper__button"
            :class="{
              active: selectedTable === 'Б Дозволи' || selectedTable === 'B Licenca'
            }"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'Б Дозволи'
                : language === 'AL' || language === 'АЛ'
                ? 'B licenca'
                : language === 'EN' || language === 'АН'
                ? 'Б Дозволи EN'
                : 'B licenca'
            "
            @click="language === 'МК' || language === 'MK' ? changeSelectedTable('Б Дозволи') : changeSelectedTable('B Licenca')"
          />
          <div
            class="selector-wrapper__button"
            :class="{
              active: selectedTable === 'Елаборати МЖСПП' || selectedTable === 'Elaborate MMJPH'
            }"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'Елаборати МЖСПП'
                : language === 'AL' || language === 'АЛ'
                ? 'Elaborate MMJPH'
                : language === 'EN' || language === 'АН'
                ? 'Елаборати EN'
                : 'Elaborate MMJPH'
            "
            @click="language === 'МК' || language === 'MK' ? changeSelectedTable('Елаборати МЖСПП') : changeSelectedTable('Elaborate MMJPH')"
          />
          <div
            class="selector-wrapper__button"
            :class="{
              active: selectedTable === 'Елаборати' || selectedTable === 'Elaborate'
            }"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'Елаборати Општини'
                : language === 'AL' || language === 'АЛ'
                ? 'Komuna elaborate'
                : language === 'EN' || language === 'АН'
                ? 'Елаборати Општини EN'
                : 'Komuna elaborate'
            "
            @click="language === 'МК' || language === 'MK' ? changeSelectedTable('Елаборати') : changeSelectedTable('Elaborate')"
          />
        </div>
        <div
          class="selctor-wrapper selector-wrapper__bottom"
          v-if="selectedTable !== 'Инспекциски извештаи' || selectedTable !== 'Raporte të inspektimit'"
        >
          <div
            class="selector-wrapper__item"
            v-if="
              selectedTable !== 'Елаборати' &&
              selectedTable !== 'Елаборати МЖСПП' &&
              selectedTable !== 'Elaborate' &&
              selectedTable !== 'Elaborate MMJPH' 
            "
          >
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'Пребарувај'
                  : 'Kërko'
              "
            />

            <input
              v-if="
                selectedTable === 'А Дозволи' || selectedTable === 'Б Дозволи' || 
                selectedTable === 'A Licenca' || selectedTable === 'B Licenca' 
              "
              class="selector-wrapper__input"
              v-model="searchInstalacija"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Инсталација'
                  : 'Instalacion'
              "
            />
          </div>

          <div
            class="selector-wrapper__item"
            v-if="
              selectedTable === 'Елаборати' ||
                selectedTable === 'Елаборати МЖСПП' ||
                selectedTable === 'Elaborate MMJPH' ||
                selectedTable === 'Elaborate'
            "
          >
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'Пребарувај'
                  : 'Kërko'
              "
            />

            <input
              class="selector-wrapper__input"
              v-model="searchElaborati"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Назив на субјект'
                  : language === 'АЛ' || language === 'AL'
                  ? 'Emri i subjektit'
                  : ''
              "
            />
          </div>

          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'Општина'
                  : 'Komuna'
              "
            />

            <v-select
              v-model="selectedOpstina"
              :options="returnOpstini"
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>

          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'Од / ден месец година'
                  : 'Nga / Data e lëshimit të lejes'
              "
            />
            <datepicker
              ref="dp1"
              v-model="selectedStartDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'До / ден месец година'
                  : 'Deri në / Data e lëshimit të lejes'
              "
            />
            <datepicker
              ref="dp2"
              v-model="selectedEndDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
        </div>
        <ve-table
          v-if="tableData.length > 0"
          :columns="language === 'MK' || language === 'МК' ? columns : columnsAl"
          :fixed-header="true"
          :max-height="500"
          :table-data="tableData"
        />
        <div v-else class="bar-chart__content flex-center green-background">
          <span
            class="no-data"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'Нема достапни податоци'
                : language === 'АЛ' || language === 'AL'
                ? 'Nuk ka të dhëna të disponueshme '
                : ''
            "
          />
        </div>
        <div class="table-pagination">
          <ve-pagination
            v-if="tableData.length > 0"
            :total="totalCount"
            :page-index="pageIndex"
            :page-size="pageSize"
            @on-page-number-change="pageNumberChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Map -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[3]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content="slotProps">
        <div class="selector-wrapper">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="map-marker-alt" size="1x" />
            <label class="title">{{ slotProps.inputData.subTitle }}</label>
          </div>
          <div class="selector-wrapper__item">
            <label class="title" v-text="slotProps.inputData.headerSubtitle" />
            <v-select
              v-model="typeFilter"
              :options="
                language === 'МК' || language === 'MK'
                  ? typeFilters
                  : typeFiltersAL
              "
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title" v-text="language === 'MK' || language === 'МК' ? 'Општина' : 'Komuna'" />
            <v-select
              v-model="selectedOpstina"
              :options="returnOpstini"
              :reduce="name => name.name"
              label="name"
              class="chart-chooser"
            />
          </div>
          <div class="selector-wrapper__item">
            <label v-if="language === 'MK' || language === 'МК'" class="title" v-text="'Пребарувај'" />
            <label v-else class="title">
              Kërko &nbsp;
            </label>
            <input
              v-if="
                typeFilter === 'A Дозволи' ||
                typeFilter === 'Б Дозволи' ||
                typeFilter === 'A Licenca' ||
                typeFilter === 'B Licenca' ||
                selectedTable === 'А Дозволи' ||
                selectedTable === 'Б Дозволи' ||
                selectedTable === 'A Licenca' ||
                selectedTable === 'B Licenca'
              "
              class="selector-wrapper__input"
              v-model="searchInstalacija"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Инсталација'
                  : 'Instalacion'
              "
            />
            <input
              v-else
              class="selector-wrapper__input"
              v-model="searchElaborati"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Назив на субјект'
                  : 'Emri i subjektit'
              "
            />
          </div>
        </div>
        <div class="selector-wrapper">
          <div class="selector-wrapper__item" />
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="slotProps.inputData.headerDescription"
            />
            <datepicker
              ref="dp1"
              :placeholder="
                language === 'MK' || language === 'МК'
                  ? 'Почетен датум'
                  : language === 'AL' || language === 'АЛ'
                  ? 'Почетен датум AL'
                  : language === 'EN' || language === 'АН'
                  ? 'Почетен датум EN'
                  : 'Почетен датум AL'
              "
              v-model="selectedStartDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
          <div class="selector-wrapper__item">
            <label class="title">{{
              slotProps.inputData.headerButtonText
            }}</label>
            <datepicker
              ref="dp2"
              :placeholder="
                language === 'MK' || language === 'МК'
                  ? 'Краен датум'
                  : language === 'AL' || language === 'АЛ'
                  ? 'Краен датум AL'
                  : language === 'EN' || language === 'АН'
                  ? 'Краен датум EN'
                  : 'Краен датум AL'
              "
              v-model="selectedEndDate"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
          <div class="selector-wrapper__item" />
        </div>
        <div class="bar-chart">
          <div class="bar-chart__header">
            <div class="icon">
              <font-awesome-icon icon="chart-bar" size="1x" />
              <span class="station" v-text="typeFilter" />
              <span
                class="station"
                v-text="
                  language === 'MK' || language === 'МК'
                    ? 'Од: ' +
                      getFormatDate(selectedStartDate) +
                      ' | До: ' +
                      getFormatDate(selectedEndDate)
                    : '' || language === 'AL' || language === 'АЛ'
                    ? 'Nga ' +
                      getFormatDate(selectedStartDate) +
                      ' | Deri më ' +
                      getFormatDate(selectedEndDate)
                    : '' || language === 'EN' || language === 'АН'
                    ? 'Од: EN ' +
                      getFormatDate(selectedStartDate) +
                      ' | До: EN ' +
                      getFormatDate(selectedEndDate)
                    : ''
                "
              />
            </div>
          </div>
        </div>
        <div class="map__wrapper">
          <l-map
            :center="mapOptions.center"
            :zoom="isMobile ? 7 : mapOptions.zoom"
          >
            <l-tile-layer
              :attribution="mapOptions.attribution"
              :url="mapOptions.url"
            />
            <l-marker
              v-for="(value, key) in coordinates"
              :key="key"
              :lat-lng="[
                value.opstinis ? value.opstinis.x : null,
                value.opstinis ? value.opstinis.y : null
              ]"
            >
              <l-popup>
                <div
                  v-if="value.registar_types_id === 3"
                  class="inside"
                  @click="
                    selectTableAndScroll(
                      getTableNameElaborati(value.registar_types_id, value.type)
                    )
                  "
                >
                  <strong v-if="value.opstinis" v-text="value.opstinis.name" />
                  <div v-if="language === 'МК' || language === 'MK'">
                    Број на
                    {{ typeFilter }}
                    :
                    {{ calcuateNumberOfOpstini(value.opstinis_id) }}
                  </div>
                  <div v-else>
                    Numri në
                    {{ typeFilter }}
                    :
                    {{ calcuateNumberOfOpstini(value.opstinis_id) }}
                  </div>
                </div>
                <div
                  v-else
                  class="inside"
                  @click="
                    selectTableAndScroll(getTableName(value.registar_types_id))
                  "
                >
                  <strong v-if="value.opstinis" v-text="value.opstinis.name" />
                  <div v-if="language === 'МК' || language === 'MK'">
                    Број на
                    {{ typeFilter }}
                    :
                    {{ calcuateNumberOfOpstini(value.opstinis_id) }}
                  </div>
                  <div v-else>
                    Numri në
                    {{ typeFilter }}
                    :
                    {{ calcuateNumberOfOpstini(value.opstinis_id) }}
                  </div>
                </div>
              </l-popup>
            </l-marker>
          </l-map>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Datoteka / Slider -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[1]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content>
        <div class="app-wrap">
          <div class="app">
            <div class="cardList">
              <div class="cards__wrapper">
                <div
                  class="card current--card"
                  @click="smoothScroll('inspekciski-izvestai')"
                >
                  <div class="card__image">
                    <img
                      src="https://app.imgforce.com/images/user/yF0_1649201153_Nova-slika-pozadina-2.png"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="card next--card"
                  @click="smoothScroll('inspekciski-izvestai')"
                >
                  <div class="card__image">
                    <img
                      src="https://app.imgforce.com/images/user/mLE_1647039889_movin.jpg"
                      alt=""
                    />
                  </div>
                </div>

                <div
                  class="card previous--card"
                  @click="smoothScroll('inspekciski-izvestai')"
                >
                  <div class="card__image">
                    <img
                      src="https://app.imgforce.com/images/user/rxq_1647040047_istockphoto-1155511952-612x612.jpg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="infoList">
              <div class="info__wrapper">
                <div class="info current--info">
                  <h1
                    class="text name"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Инспекциски извештаи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Raporte tē inspektimit '
                        : language === 'EN' || language === 'АН'
                        ? 'Инспекциски извештаи EN'
                        : 'Raporte tē inspektimit '
                    "
                  />
                  <h4
                    class="text location"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Извештаи од инспекциски надзор'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Raporte të mbikqyrjeve inspektuese'
                        : language === 'EN' || language === 'АН'
                        ? 'Извештаи од инспекциски надзор EN'
                        : 'Raporte të mbikqyrjeve inspektuese'
                    "
                  />
                  <p
                    class="text description"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Извештаи од инспекциски надзор'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Raporte të mbikqyrjeve inspektuese'
                        : language === 'EN' || language === 'АН'
                        ? 'Извештаи од инспекциски надзор EN'
                        : 'Raporte të mbikqyrjeve inspektuese'
                    "
                  />
                </div>

                <div class="info next--info">
                  <h1
                    class="text name"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Еколошки дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Licenca ekologjike'
                        : language === 'EN' || language === 'АН'
                        ? 'Еколошки дозволи EN'
                        : 'Licenca ekologjike'
                    "
                  />
                  <h4
                    class="text location"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Б-дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'B-Licenca'
                        : language === 'EN' || language === 'АН'
                        ? 'Б-дозволи EN'
                        : 'B-Licenca'
                    "
                  />
                  <p
                    class="text description"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Б-дозволи'
                        : language === 'AL' || language === 'АЛ'
                        ? 'B-Licenca'
                        : language === 'EN' || language === 'АН'
                        ? 'Б-дозволи EN'
                        : 'B-Licenca'
                    "
                  />
                </div>

                <div class="info previous--info">
                  <h1
                    class="text name"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Елаборати'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Elaborate'
                        : language === 'EN' || language === 'АН'
                        ? 'Елаборати EN'
                        : 'Elaborate'
                    "
                  />
                  <h4
                    class="text location"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Издадени од општините'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Lëshuar nga komunat'
                        : language === 'EN' || language === 'АН'
                        ? 'Издадени од општините EN'
                        : 'Lëshuar nga komunat'
                    "
                  />
                  <p
                    class="text description"
                    v-text="
                      language === 'MK' || language === 'МК'
                        ? 'Издадени од општините'
                        : language === 'AL' || language === 'АЛ'
                        ? 'Lëshuar nga komunat'
                        : language === 'EN' || language === 'АН'
                        ? 'Издадени од општините EN'
                        : 'Lëshuar nga komunat'
                    "
                  />
                </div>
              </div>
            </div>

            <div class="app__bg">
              <div class="app__bg__image current--image">
                <img
                  src="https://app.imgforce.com/images/user/yF0_1649201153_Nova-slika-pozadina-2.png"
                  alt=""
                />
              </div>
              <div class="app__bg__image next--image">
                <img
                  src="https://app.imgforce.com/images/user/yF0_1649201153_Nova-slika-pozadina-2.png"
                  alt=""
                />
              </div>
              <div class="app__bg__image previous--image">
                <img
                  src="https://app.imgforce.com/images/user/yF0_1649201153_Nova-slika-pozadina-2.png"
                  alt=""
                />
              </div>
            </div>
          </div>

          <svg class="icons" style="display: none">
            <symbol
              id="arrow-left"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <polyline
                points="328 112 184 256 328 400"
                style="
                  fill: none;
                  stroke: #fff;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 48px;
                "
              />
            </symbol>
            <symbol
              id="arrow-right"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <polyline
                points="184 112 328 256 184 400"
                style="
                  fill: none;
                  stroke: #fff;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 48px;
                "
              />
            </symbol>
          </svg>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Inspekciski izvestai -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[4]"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
      </template>
      <template v-slot:content>
        <div class="selector-wrapper selector-wrapper__bottom">
          <div class="selector-wrapper__item">
            <font-awesome-icon icon="table" size="1x" />
            <label
              class="title"
              v-text="
                language === 'MK' || language === 'МК'
                  ? 'Филтрирај податоци'
                  : 'Filtro të dhënat'
              "
            />
          </div>
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'Пребарувај'
                  : 'Kërko'
              "
            />
            <input
              class="selector-wrapper__input"
              v-model="searchOperator"
              type="text"
              :placeHolder="
                language === 'MK' || language === 'МК'
                  ? 'Име на oператор / место'
                  : language === 'АЛ' || language === 'AL'
                  ? 'Emri i operatorit / vendndodhja'
                  : ''
              "
            />
          </div>
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'Од / ден месец година'
                  : 'Nga / Data e lëshimit të lejes'
              "
            />
            <datepicker
              ref="dp1"
              v-model="selectedStartDateSecondTable"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
          <div class="selector-wrapper__item">
            <label
              class="title"
              v-text="
                language === 'МК' || language === 'MK'
                  ? 'До / ден месец година'
                  : 'Deri në / Data e lëshimit të lejes'
              "
            />
            <datepicker
              ref="dp2"
              v-model="selectedEndDateSecondTable"
              :minimumView="'day'"
              :maximumView="'month'"
              :initialView="'month'"
              :format="'d MMMM yyyy'"
              :language="language === 'МК' || language === 'MK' ? mk : en"
            />
          </div>
        </div>
        <ve-table
          v-if="tableDataSecond.length > 0"
          border-y
          :columns="language === 'MK' || language === 'МК' ? columnsSecondTable : columnsSecondTableAl"
          :fixed-header="true"
          :max-height="500"
          :table-data="tableDataSecond"
        />
        <div v-else class="bar-chart__content flex-center green-background">
          <span
            class="no-data"
            v-text="
              language === 'MK' || language === 'МК'
                ? 'Нема достапни податоци'
                : language === 'АЛ' || language === 'AL'
                ? 'Nuk ka të dhëna të disponueshme '
                : ''
            "
          />
        </div>
        <div class="table-pagination">
          <ve-pagination
            v-if="tableDataSecond.length > 0"
            :total="totalCountSecondTable"
            :page-index="pageIndexSecondTable"
            :page-size="pageSizeSecondTable"
            @on-page-number-change="pageNumberChangeSecondTable"
            @on-page-size-change="pageSizeChangeSecondTable"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Поврзани линкови -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[5]"
      @button-clicked="redirectToExternal"
    >
      <template v-slot:header="slotProps">
        <h2 class="header__title">
          <span v-text="slotProps.inputData.title" />
        </h2>
        <div
          class="header__description"
          v-html="slotProps.inputData.description"
        />
      </template>
    </flexi-wrapper>

    <flexi-advanced-last-card
      v-if="inputData.homePage"
      :inputData="inputData.homePage.last_card"
      :navigationPages="inputData.navigationPages"
      :loadingForm="true"
    >
      <template v-slot:logo>
        <span @click="goToRoute('/')" class="last-card__logo" />
      </template>
    </flexi-advanced-last-card>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import { mk, en } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { gsap } from "gsap";
var imagesLoaded = require("imagesloaded");
import CSSRulePlugin from "gsap/CSSRulePlugin";
import scrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(CSSRulePlugin, scrollTrigger);

const ALL = "Сите";

const ALLAl = "Të gjitha"

const ALLSelected = [
  {
    name: "Сите"
  }
];

const ALLSelectedAl = [
  {
    name: "Të gjitha"
  }
];

const coordinatesTypes = {
  A_DOZVOLI: "А Дозволи",
  B_DOZVOLI: "Б Дозволи",
  ELABORATI_MZSPP: "Елаборати МЖСПП",
  ELABORATI: "Елаборати"
};

const coordinatesTypesAL = {
  A_DOZVOLI: "A Licenca",
  B_DOZVOLI: "B Licenca",
  ELABORATI_MZSPP: "Elaborate MMJPH",
  ELABORATI: "Elaborate"
};

export default {
  name: "home",
  watch: {
    language() {
      if (this.language === "MK" || this.language === "МК") {
        this.typeFilter = coordinatesTypes.A_DOZVOLI;
        this.selectedOpstina = ALL
      } else if (this.language === "AL" || this.language === "АЛ") {
        this.typeFilter = coordinatesTypesAL.A_DOZVOLI;
        this.selectedOpstina = ALLAl
      }
    },
    selectedCoordinatesType: {
      handler(type) {
        this.pageIndex = 1;
        this.typeFilter = type;
      }
    },
    selectedStartDate: {
      handler(date) {
        this.setSelectedStartDate(date);
      },
      immediate: true
    },
    selectedEndDate: {
      handler(date) {
        this.setSelectedEndDate(date);
      },
      immediate: true
    },
    selectedOpstina: {
      handler() {
        this.pageIndex = 1;
      }
    },
    typeFilter: {
      handler(filter) {
        this.pageIndex = 1;
        if (this.language === "MK" || this.language === "МК") {
          if (filter === "А Дозволи") {
            this.changeSelectedTable("А Дозволи");
            this.searchElaborati = "";
            this.searchInstalacija = "";
            this.setSelectedCoordinatesType(coordinatesTypes.A_DOZVOLI);
            this.setSelectedItem(this.coordinatesData.a_dozvoli);
          } else if (filter === coordinatesTypes.B_DOZVOLI) {
            this.changeSelectedTable("Б Дозволи");
            this.setSelectedCoordinatesType(coordinatesTypes.B_DOZVOLI);
            this.setSelectedItem(this.coordinatesData.b_dozvoli);
            this.searchElaborati = "";
            this.searchInstalacija = "";
          } else if (filter === coordinatesTypes.ELABORATI) {
            this.changeSelectedTable("Елаборати");
            this.searchElaborati = "";
            this.searchInstalacija = "";
            this.setSelectedCoordinatesType(coordinatesTypes.ELABORATI);
            this.setSelectedItem(
            this.coordinatesData.elaborati.filter(elem => {
              return elem.type === true || elem.type === 1;
            })
            );
          } else if (filter === coordinatesTypes.ELABORATI_MZSPP) {
            this.changeSelectedTable("Елаборати МЖСПП");
            this.searchElaborati = "";
            this.searchInstalacija = "";
            this.setSelectedCoordinatesType(coordinatesTypes.ELABORATI_MZSPP);
            this.setSelectedItem(
            this.coordinatesData.elaborati.filter(elem => {
              return elem.type === false || elem.type === 0;
            })
            );
          }
        } else if (this.language === "AL" || this.language === "АЛ") {
          if (filter === coordinatesTypesAL.A_DOZVOLI) {
            this.changeSelectedTable('A Licenca');
            this.searchElaborati = "";
            this.searchInstalacija = "";
            this.setSelectedCoordinatesType(coordinatesTypesAL.A_DOZVOLI);
            this.setSelectedItem(this.coordinatesData.a_dozvoli);
          } else if (filter === coordinatesTypesAL.B_DOZVOLI) {
            this.changeSelectedTable("B Licenca");
            this.searchInstalacija = "";
            this.searchElaborati = "";
            this.setSelectedCoordinatesType(coordinatesTypesAL.B_DOZVOLI);
            this.setSelectedItem(this.coordinatesData.b_dozvoli);
          }  else if (filter === coordinatesTypesAL.ELABORATI) {
            this.changeSelectedTable("Elaborate");
            this.searchElaborati = "";
            this.searchInstalacija = "";
            this.setSelectedCoordinatesType(coordinatesTypesAL.ELABORATI);
            this.setSelectedItem(
            this.coordinatesData.elaborati.filter(elem => {
              return elem.type === true || elem.type === 1;
            })
            );
          } else if (filter === coordinatesTypesAL.ELABORATI_MZSPP) {
            this.changeSelectedTable("Elaborate MMJPH");
            this.searchInstalacija = "";
            this.searchElaborati = "";
            this.setSelectedCoordinatesType(coordinatesTypesAL.ELABORATI_MZSPP);
            this.setSelectedItem(
            this.coordinatesData.elaborati.filter(elem => {
              return elem.type === false || elem.type === 0;
            })
            );
          }
        }
      }
    },
    selectedTable: {
      handler(tableName) {
        this.typeFilter = tableName;
      }
    }
  },
  components: {
    datepicker: Datepicker,
    vSelect
  },
  computed: {
    ...mapGetters([
      "coordinatesData",
      "inputData",
      "language",
      "opstini",
      "selectedItem",
      "selectedCoordinatesType",
      "selectedTable",
      "theme"
    ]),
    coordinates() {
      if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
        if (
          this.typeFilter === "Елаборати" ||
          this.typeFilter === "Елаборати МЖСПП" ||
          this.typeFilter === "Elaborate" ||
          this.typeFilter === "Elaborate MMJPH"
        ) {
          return this.selectedItem.filter(item => {
            return (
              (item.naziv_na_subjektot
                .toLowerCase()
                .includes(this.searchElaborati.toLowerCase()) ||
                item.lokacija
                  .toLowerCase()
                  .includes(this.searchElaborati.toLowerCase())) &&
              moment(item.date).format("YYYY/MM/DD") >=
                moment(this.selectedStartDate).format("YYYY/MM/DD") &&
              moment(item.date).format("YYYY/MM/DD") <=
                moment(this.selectedEndDate).format("YYYY/MM/DD")
            );
          });
        } else {
          return this.selectedItem.filter(item => {
            return (
              item.instalacija
                .toLowerCase()
                .includes(this.searchInstalacija.toLowerCase()) &&
              moment(item.date).format("YYYY/MM/DD") >=
                moment(this.selectedStartDate).format("YYYY/MM/DD") &&
              moment(item.date).format("YYYY/MM/DD") <=
                moment(this.selectedEndDate).format("YYYY/MM/DD")
            );
          });
        }
      } else {
        if (
          this.typeFilter === "Елаборати" ||
          this.typeFilter === "Елаборати МЖСПП" ||
          this.typeFilter === "Elaborate" ||
          this.typeFilter === "Elaborate MMJPH"
        ) {
          return this.selectedItem.filter(item => {
            return (
              (item.naziv_na_subjektot
                .toLowerCase()
                .includes(this.searchElaborati.toLowerCase()) ||
                item.lokacija
                  .toLowerCase()
                  .includes(this.searchElaborati.toLowerCase())) &&
              moment(item.date).format("YYYY/MM/DD") >=
                moment(this.selectedStartDate).format("YYYY/MM/DD") &&
              moment(item.date).format("YYYY/MM/DD") <=
                moment(this.selectedEndDate).format("YYYY/MM/DD") &&
              item.opstinis.name === this.selectedOpstina
            );
          });
        } else {
          return this.selectedItem.filter(item => {
            return (
              item.instalacija
                .toLowerCase()
                .includes(this.searchInstalacija.toLowerCase()) &&
              moment(item.date).format("YYYY/MM/DD") >=
                moment(this.selectedStartDate).format("YYYY/MM/DD") &&
              moment(item.date).format("YYYY/MM/DD") <=
                moment(this.selectedEndDate).format("YYYY/MM/DD") &&
              item.opstinis.name === this.selectedOpstina
            );
          });
        }
      }
    },
    returnOpstini() {
      if (this.language === 'MK' || this.language === 'МК') {
        return [...ALLSelected, ...this.opstini];
      } else {
        return [...ALLSelectedAl, ...this.opstini];
      }
    },
    tableData() {
      const { pageIndex, pageSize } = this;
      if (this.selectedTable === "А Дозволи" || this.selectedTable === "A Licenca") {
        if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
          return this.selectedItem
            .filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD")
              );
            })
            .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
        } else {
          return this.selectedItem
            .filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD") &&
                item.opstinis.name === this.selectedOpstina
              );
            })
            .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
        }
      } else if (this.selectedTable === "Б Дозволи" || this.selectedTable === "B Licenca") {
        if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
          return this.selectedItem
            .filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD")
              );
            })
            .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
        } else {
          return this.selectedItem
            .filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD") &&
                item.opstinis.name === this.selectedOpstina
              );
            })
            .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
        }
      } else if (
        this.selectedTable === "Елаборати" ||
        this.selectedTable === "Елаборати МЖСПП" ||
        this.selectedTable === "Elaborate MMJPH" ||
        this.selectedTable === "Elaborate"
      ) {
        if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
          return this.selectedItem
            .filter(item => {
              return (
                (item.naziv_na_subjektot
                  .toLowerCase()
                  .includes(this.searchElaborati.toLowerCase()) ||
                  item.lokacija
                    .toLowerCase()
                    .includes(this.searchElaborati.toLowerCase())) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD")
              );
            })
            .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
        } else {
          return this.selectedItem
            .filter(item => {
              return (
                (item.naziv_na_subjektot
                  .toLowerCase()
                  .includes(this.searchElaborati.toLowerCase()) ||
                  item.lokacija
                    .toLowerCase()
                    .includes(this.searchElaborati.toLowerCase())) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD") &&
                item.opstinis.name === this.selectedOpstina
              );
            })
            .slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
        }
      } else {
        return this.selectedItem.slice(
          (pageIndex - 1) * pageSize,
          pageIndex * pageSize
        );
      }
    },
    tableDataSecond() {
      const { pageIndexSecondTable, pageSizeSecondTable } = this;
      return this.coordinatesData.izvestai
        .filter(item => {
          return (
            (item.operator_ime
              .toLowerCase()
              .includes(this.searchOperator.toLowerCase()) ||
              item.operator_adresa
                .toLowerCase()
                .includes(this.searchOperator.toLowerCase())) &&
            moment(item.created_at).format("YYYY/MM/DD") >=
              moment(this.selectedStartDateSecondTable).format("YYYY/MM/DD") &&
            moment(item.created_at).format("YYYY/MM/DD") <=
              moment(this.selectedEndDateSecondTable).format("YYYY/MM/DD")
          );
        })
        .slice(
          (pageIndexSecondTable - 1) * pageSizeSecondTable,
          pageIndexSecondTable * pageSizeSecondTable
        );
    },
    columns() {
      if (
        this.selectedTable === "А Дозволи" ||
        this.selectedTable === "Б Дозволи"
      ) {
        return [
          {
            field: "",
            key: "a",
            title: "#",
            align: "center",
            renderBodyCell: ({ rowIndex }) => {
              return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
            }
          },
          {
            field: "instalacija",
            key: "b",
            title: "Инсталација",
            align: "center"
          },
          {
            field: "vid_na_dozvola",
            key: "c",
            title: "Вид на дозвола",
            align: "left"
          },
          {
            field: "",
            key: "d",
            title: "Општина",
            align: "left",
            renderBodyCell: ({ row }) => {
              return row.opstinis.name;
            }
          },
          {
            field: "arhivski_broj",
            key: "e",
            title: "Архивски броj",
            align: "left"
          },
          {
            field: "date",
            key: "f",
            title: "Датум на издадена дозвола",
            width: "",
            renderBodyCell: ({ row }) => {
              return this.getFormatDate(row.date);
            }
          }
        ];
      } else if (
        this.selectedTable === "Елаборати" ||
        this.selectedTable === "Елаборати МЖСПП"
      ) {
        return [
          {
            field: "",
            key: "a",
            title: "#",
            align: "center",
            renderBodyCell: ({ rowIndex }) => {
              return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
            }
          },
          {
            field: "naziv_na_subjektot",
            key: "b",
            title: "Назив на субјектот",
            align: "center"
          },
          {
            field: "",
            key: "c",
            title: "Општина",
            align: "left",
            renderBodyCell: ({ row }) => {
              return row.opstinis.name;
            }
          },
          {
            field: "lokacija",
            key: "d",
            title: "Локација",
            align: "left"
          },
          {
            field: "dejnost",
            key: "e",
            title: "Дејност",
            align: "left"
          },
          {
            field: "arhivski_broj",
            key: "f",
            title: "Архивски број",
            width: ""
          },
          {
            field: "date",
            key: "g",
            title: "Датум на издадена дозвола",
            width: "",
            renderBodyCell: ({ row }) => {
              return this.getFormatDate(row.date);
            }
          }
        ];
      } else {
        return [];
      }
    },
    columnsAl() {
      if (
        this.selectedTable === "A Licenca" ||
        this.selectedTable === "B Licenca"
      ) {
        return [
          {
            field: "",
            key: "a",
            title: "#",
            align: "center",
            renderBodyCell: ({ rowIndex }) => {
              return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
            }
          },
          {
            field: "instalacija",
            key: "b",
            title: "Instalimi",
            align: "center"
          },
          {
            field: "vid_na_dozvola",
            key: "c",
            title: "Lloji i lejes",
            align: "left"
          },
          {
            field: "",
            key: "d",
            title: "Komuna",
            align: "left",
            renderBodyCell: ({ row }) => {
              return row.opstinis.name;
            }
          },
          {
            field: "arhivski_broj",
            key: "e",
            title: "Numri i arkivit",
            align: "left"
          },
          {
            field: "date",
            key: "f",
            title: "Data e lëshimit të lejes",
            width: "",
            renderBodyCell: ({ row }) => {
              return this.getFormatDate(row.date);
            }
          }
        ];
      } else if (
        this.selectedTable === "Elaborate" ||
        this.selectedTable === "Elaborate MMJPH"
      ) {
        return [
          {
            field: "",
            key: "a",
            title: "#",
            align: "center",
            renderBodyCell: ({ rowIndex }) => {
              return (this.pageIndex - 1) * this.pageSize + rowIndex + 1;
            }
          },
          {
            field: "naziv_na_subjektot",
            key: "b",
            title: "Emri i subjektit",
            align: "center"
          },
          {
            field: "",
            key: "c",
            title: "Komuna",
            align: "left",
            renderBodyCell: ({ row }) => {
              return row.opstinis.name;
            }
          },
          {
            field: "lokacija",
            key: "d",
            title: "Vendndodhja",
            align: "left"
          },
          {
            field: "dejnost",
            key: "e",
            title: "Profesioni",
            align: "left"
          },
          {
            field: "arhivski_broj",
            key: "f",
            title: "Numri i arkivit",
            width: ""
          },
          {
            field: "date",
            key: "g",
            title: "Data e lëshimit të lejes",
            width: "",
            renderBodyCell: ({ row }) => {
              return this.getFormatDate(row.date);
            }
          }
        ];
      } else {
        return [];
      }
    },
    columnsSecondTable() {
      return [
        {
          field: "",
          key: "a",
          title: "#",
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return (
              (this.pageIndexSecondTable - 1) * this.pageSizeSecondTable +
              rowIndex +
              1
            );
          }
        },
        {
          title: "Оператор",
          children: [
            {
              field: "operator_ime",
              key: "b",
              title: "Име",
              align: "center",
              width: "100%"
            },
            {
              field: "operator_adresa",
              key: "c",
              title: "Адреса",
              align: "left",
              width: "100%"
            }
          ]
        },
        {
          title: "Место",
          children: [
            {
              field: "ime_na_mesto",
              key: "d",
              title: "Име",
              align: "left",
              width: "100%"
            },
            {
              field: "adresa",
              key: "e",
              title: "Адреса",
              width: "100%"
            },
            {
              field: "vid_na_aktivnost",
              key: "f",
              title: "Вид на активноста",
              width: "100%"
            },
            {
              field: "specifikacija",
              key: "g",
              title: "Спец. на инсталација",
              width: "100%"
            },
            {
              field: "br_dozvola",
              key: "h",
              title: "Бр. на дозвола",
              width: "100%"
            }
          ]
        },
        {
          title: "Датум на увид",
          children: [
            {
              field: "pocetok_na_uvid",
              key: "i",
              title: "Почеток",
              align: "left",
              width: "100%",
              renderBodyCell: ({ row }) => {
                return this.getFormatDate(row.pocetok_na_uvid);
              }
            },
            {
              field: "zavrsetok_na_uvid",
              key: "j",
              title: "Завршеток",
              width: "100%",
              renderBodyCell: ({ row }) => {
                return this.getFormatDate(row.zavrsetok_na_uvid);
              }
            }
          ]
        },
        {
          title: "Позадина и цел на инспекцијата",
          children: [
            {
              field: "vid_na_inspekcijas.name",
              key: "k",
              title: "Најавена/ненајавена",
              align: "left",
              width: "100%"
            },
            {
              field: "cel",
              key: "l",
              title: "Цел",
              width: "100%"
            },
            {
              field: "pravna_pozadina",
              key: "m",
              title: "Правна позадина",
              width: "100%"
            },
            {
              field: "kontroliran_period",
              key: "n",
              title: "Период",
              width: "100%"
            }
          ]
        },
        {
          field: "znacitelni_neusoglasenosti",
          key: "e",
          title: "Неусогласености ",
          width: "100%"
        },
        {
          field: "opis_na_natamosni_dejstvija",
          key: "e",
          title: "Мерки",
          width: "100%"
        }
      ];
    },
    columnsSecondTableAl() {
      return [
        {
          field: "",
          key: "a",
          title: "#",
          align: "center",
          renderBodyCell: ({ rowIndex }) => {
            return (
              (this.pageIndexSecondTable - 1) * this.pageSizeSecondTable +
              rowIndex +
              1
            );
          }
        },
        {
          title: "Operatori",
          children: [
            {
              field: "operator_ime",
              key: "b",
              title: "Emri",
              align: "center",
              width: "100%"
            },
            {
              field: "operator_adresa",
              key: "c",
              title: "Adresë",
              align: "left",
              width: "100%"
            }
          ]
        },
        {
          title: "Vendi",
          children: [
            {
              field: "ime_na_mesto",
              key: "d",
              title: "Emri",
              align: "left",
              width: "100%"
            },
            {
              field: "adresa",
              key: "e",
              title: "Adresë",
              width: "100%"
            },
            {
              field: "vid_na_aktivnost",
              key: "f",
              title: "Lloji i aktivitetit",
              width: "100%"
            },
            {
              field: "specifikacija",
              key: "g",
              title: "Spec. në instalim",
              width: "100%"
            },
            {
              field: "br_dozvola",
              key: "h",
              title: "Nr. me leje",
              width: "100%"
            }
          ]
        },
        {
          title: "Data e inspektimit",
          children: [
            {
              field: "pocetok_na_uvid",
              key: "i",
              title: "Filloni",
              align: "left",
              width: "100%",
              renderBodyCell: ({ row }) => {
                return this.getFormatDate(row.pocetok_na_uvid);
              }
            },
            {
              field: "zavrsetok_na_uvid",
              key: "j",
              title: "Fund",
              width: "100%",
              renderBodyCell: ({ row }) => {
                return this.getFormatDate(row.zavrsetok_na_uvid);
              }
            }
          ]
        },
        {
          title: "Sfondi dhe qëllimi i inspektimit",
          children: [
            {
              field: "vid_na_inspekcijas.name",
              key: "k",
              title: "I shpallur / i paparalajmëruar",
              align: "left",
              width: "100%"
            },
            {
              field: "cel",
              key: "l",
              title: "Qëllimi",
              width: "100%"
            },
            {
              field: "pravna_pozadina",
              key: "m",
              title: "Sfondi juridik",
              width: "100%"
            },
            {
              field: "kontroliran_period",
              key: "n",
              title: "Periudha",
              width: "100%"
            }
          ]
        },
        {
          field: "znacitelni_neusoglasenosti",
          key: "e",
          title: "Mospërputhjet ",
          width: "100%"
        },
        {
          field: "opis_na_natamosni_dejstvija",
          key: "e",
          title: "Masat",
          width: "100%"
        }
      ];
    },
    totalCount() {
      if (this.coordinatesData) {
        if (this.selectedTable === "А Дозволи" || this.selectedTable === "A Licenca") {
          if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
            const totalData = this.coordinatesData.a_dozvoli.filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD")
              );
            });
            return totalData.length;
          } else {
            const totalData = this.coordinatesData.a_dozvoli.filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD") &&
                item.opstinis.name === this.selectedOpstina
              );
            });
            return totalData.length;
          }
        } else if (this.selectedTable === "Б Дозволи" || this.selectedTable === "B Licenca") {
          if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
            const totalData = this.coordinatesData.b_dozvoli.filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD")
              );
            });
            return totalData.length;
          } else {
            const totalData = this.coordinatesData.b_dozvoli.filter(item => {
              return (
                item.instalacija
                  .toLowerCase()
                  .includes(this.searchInstalacija.toLowerCase()) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD") &&
                item.opstinis.name === this.selectedOpstina
              );
            });
            return totalData.length;
          }
        } else if (
          this.selectedTable === "Елаборати" ||
          this.selectedTable === "Елаборати МЖСПП" ||
          this.selectedTable === "Elaborate MMJPH" ||
          this.selectedTable === "Elaborate"
        ) {
          if (this.selectedOpstina === ALL || this.selectedOpstina === ALLAl) {
            const totalData = this.selectedItem.filter(item => {
              return (
                (item.naziv_na_subjektot
                  .toLowerCase()
                  .includes(this.searchElaborati.toLowerCase()) ||
                  item.lokacija
                    .toLowerCase()
                    .includes(this.searchElaborati.toLowerCase())) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD")
              );
            });
            return totalData.length;
          } else {
            const totalData = this.selectedItem.filter(item => {
              return (
                (item.naziv_na_subjektot
                  .toLowerCase()
                  .includes(this.searchElaborati.toLowerCase()) ||
                  item.lokacija
                    .toLowerCase()
                    .includes(this.searchElaborati.toLowerCase())) &&
                moment(item.date).format("YYYY/MM/DD") >=
                  moment(this.selectedStartDate).format("YYYY/MM/DD") &&
                moment(item.date).format("YYYY/MM/DD") <=
                  moment(this.selectedEndDate).format("YYYY/MM/DD") &&
                item.opstinis.name === this.selectedOpstina
              );
            });
            return totalData.length;
          }
        } else {
          return 0;
        }
      } else return 0;
    },
    totalCountSecondTable() {
      const totalData = this.coordinatesData.izvestai.filter(item => {
        return (
          item.operator_ime
            .toLowerCase()
            .includes(this.searchOperator.toLowerCase()) &&
          moment(item.created_at).format("YYYY/MM/DD") >=
            moment(this.selectedStartDateSecondTable).format("YYYY/MM/DD") &&
          moment(item.created_at).format("YYYY/MM/DD") <=
            moment(this.selectedEndDateSecondTable).format("YYYY/MM/DD")
        );
      });
      return totalData.length;
    }
  },
  data() {
    return {
      formatDate: "DD/MM/YYYY",
      lastCard: {
        options: {
          button: false,
          date: false,
          description: false,
          img: false,
          paragraphOn: true,
          subTitle: false,
          titleClickable: false
        }
      },
      mapOptions: {
        attribution: "© 2022 Designed and powered by Akrinum OÜ",
        center: [41.589, 21.664],
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        zoom: 8
      },
      mk: mk,
      en: en,
      pageIndex: 1,
      pageIndexSecondTable: 1,
      pageSize: 10,
      pageSizeSecondTable: 10,
      searchElaborati: "",
      searchInstalacija: "",
      searchOperator: "",
      selectedEndDate: "2022/04/06",
      selectedEndDateSecondTable: "2025/01/01",
      selectedOpstina: '',
      selectedStartDate: "2010/01/01",
      selectedStartDateSecondTable: "2010/01/01",
      typeFilter: coordinatesTypes.A_DOZVOLI,
      typeFilters: [
        {
          name: coordinatesTypes.A_DOZVOLI
        },
        {
          name: coordinatesTypes.B_DOZVOLI
        },
        {
          name: coordinatesTypes.ELABORATI_MZSPP
        },
        {
          name: coordinatesTypes.ELABORATI
        }
      ],
      typeFiltersAL: [
        {
          name: coordinatesTypesAL.A_DOZVOLI
        },
        {
          name: coordinatesTypesAL.B_DOZVOLI
        },
        {
          name: coordinatesTypesAL.ELABORATI_MZSPP
        },
        {
          name: coordinatesTypesAL.ELABORATI
        }
      ]
    };
  },
  methods: {
    ...mapActions([
      "setSelectedItem",
      "setSelectedCoordinatesType",
      "setSelectedStartDate",
      "setSelectedEndDate",
      "setTable"
    ]),
    animateTopCard() {
      gsap.set(".main", {
        position: "fixed",
        background: "#fff",
        width: "100%",
        height: "100%",
        top: 0,
        left: "50%",
        x: "-50%"
      });
      gsap.set(".scrollDist", { width: "100%", height: "250%" });
      gsap
        .timeline({
          scrollTrigger: {
            trigger: ".scrollDist",
            start: "top top",
            end: "bottom bottom",
            scrub: 1
          }
        })
        .fromTo(".sky", { y: 0 }, { y: -200 }, 0)
        .fromTo(".cloud1", { y: 100 }, { y: -800 }, 0)
        .fromTo(".cloud2", { y: -150 }, { y: -500 }, 0)
        .fromTo(".cloud3", { y: -50 }, { y: -650 }, 0)
        .fromTo(".mountBg", { y: -10 }, { y: -100 }, 0)
        .fromTo(".mountMg", { y: -30 }, { y: -250 }, 0)
        .fromTo(".mountFg", { y: -50 }, { y: -600 }, 0);
    },
    animateSlider() {
      const buttons = {
        prev: document.querySelector(".btn--left"),
        next: document.querySelector(".btn--right")
      };
      const cardsContainerEl = document.querySelector(".cards__wrapper");
      const appBgContainerEl = document.querySelector(".app__bg");

      const cardInfosContainerEl = document.querySelector(".info__wrapper");

      buttons.next.addEventListener("click", () => swapCards("right"));

      buttons.prev.addEventListener("click", () => swapCards("left"));

      function swapCards(direction) {
        const currentCardEl = cardsContainerEl.querySelector(".current--card");
        const previousCardEl = cardsContainerEl.querySelector(
          ".previous--card"
        );
        const nextCardEl = cardsContainerEl.querySelector(".next--card");

        const currentBgImageEl = appBgContainerEl.querySelector(
          ".current--image"
        );
        const previousBgImageEl = appBgContainerEl.querySelector(
          ".previous--image"
        );
        const nextBgImageEl = appBgContainerEl.querySelector(".next--image");

        changeInfo(direction);
        swapCardsClass();

        removeCardEvents(currentCardEl);

        function swapCardsClass() {
          currentCardEl.classList.remove("current--card");
          previousCardEl.classList.remove("previous--card");
          nextCardEl.classList.remove("next--card");

          currentBgImageEl.classList.remove("current--image");
          previousBgImageEl.classList.remove("previous--image");
          nextBgImageEl.classList.remove("next--image");

          currentCardEl.style.zIndex = "50";
          currentBgImageEl.style.zIndex = "-2";

          if (direction === "right") {
            previousCardEl.style.zIndex = "20";
            nextCardEl.style.zIndex = "30";

            nextBgImageEl.style.zIndex = "-1";

            currentCardEl.classList.add("previous--card");
            previousCardEl.classList.add("next--card");
            nextCardEl.classList.add("current--card");

            currentBgImageEl.classList.add("previous--image");
            previousBgImageEl.classList.add("next--image");
            nextBgImageEl.classList.add("current--image");
          } else if (direction === "left") {
            previousCardEl.style.zIndex = "30";
            nextCardEl.style.zIndex = "20";

            previousBgImageEl.style.zIndex = "-1";

            currentCardEl.classList.add("next--card");
            previousCardEl.classList.add("current--card");
            nextCardEl.classList.add("previous--card");

            currentBgImageEl.classList.add("next--image");
            previousBgImageEl.classList.add("current--image");
            nextBgImageEl.classList.add("previous--image");
          }
        }
      }

      function changeInfo(direction) {
        let currentInfoEl = cardInfosContainerEl.querySelector(
          ".current--info"
        );
        let previousInfoEl = cardInfosContainerEl.querySelector(
          ".previous--info"
        );
        let nextInfoEl = cardInfosContainerEl.querySelector(".next--info");

        gsap
          .timeline()
          .to([buttons.prev, buttons.next], {
            duration: 0.2,
            opacity: 0.5,
            pointerEvents: "none"
          })
          .to(
            currentInfoEl.querySelectorAll(".text"),
            {
              duration: 0.4,
              stagger: 0.1,
              translateY: "-120px",
              opacity: 0
            },
            "-="
          )
          .call(() => {
            swapInfosClass(direction);
          })
          .call(() => initCardEvents())
          .fromTo(
            direction === "right"
              ? nextInfoEl.querySelectorAll(".text")
              : previousInfoEl.querySelectorAll(".text"),
            {
              opacity: 0,
              translateY: "40px"
            },
            {
              duration: 0.4,
              stagger: 0.1,
              translateY: "0px",
              opacity: 1
            }
          )
          .to([buttons.prev, buttons.next], {
            duration: 0.2,
            opacity: 1,
            pointerEvents: "all"
          });

        function swapInfosClass() {
          currentInfoEl.classList.remove("current--info");
          previousInfoEl.classList.remove("previous--info");
          nextInfoEl.classList.remove("next--info");

          if (direction === "right") {
            currentInfoEl.classList.add("previous--info");
            nextInfoEl.classList.add("current--info");
            previousInfoEl.classList.add("next--info");
          } else if (direction === "left") {
            currentInfoEl.classList.add("next--info");
            nextInfoEl.classList.add("previous--info");
            previousInfoEl.classList.add("current--info");
          }
        }
      }

      function updateCard(e) {
        const card = e.currentTarget;
        const box = card.getBoundingClientRect();
        const centerPosition = {
          x: box.left + box.width / 2,
          y: box.top + box.height / 2
        };
        let angle = Math.atan2(e.pageX - centerPosition.x, 0) * (35 / Math.PI);
        gsap.set(card, {
          "--current-card-rotation-offset": `${angle}deg`
        });
        const currentInfoEl = cardInfosContainerEl.querySelector(
          ".current--info"
        );
        gsap.set(currentInfoEl, {
          rotateY: `${angle}deg`
        });
      }

      function resetCardTransforms(e) {
        const card = e.currentTarget;
        const currentInfoEl = cardInfosContainerEl.querySelector(
          ".current--info"
        );
        gsap.set(card, {
          "--current-card-rotation-offset": 0
        });
        gsap.set(currentInfoEl, {
          rotateY: 0
        });
      }

      function initCardEvents() {
        const currentCardEl = cardsContainerEl.querySelector(".current--card");
        currentCardEl.addEventListener("pointermove", updateCard);
        currentCardEl.addEventListener("pointerout", e => {
          resetCardTransforms(e);
        });
      }

      initCardEvents();

      function removeCardEvents(card) {
        card.removeEventListener("pointermove", updateCard);
      }

      function init() {
        let tl = gsap.timeline();

        tl.to(cardsContainerEl.children, {
          delay: 0.15,
          duration: 0.5,
          stagger: {
            ease: "power4.inOut",
            from: "right",
            amount: 0.1
          },
          "--card-translateY-offset": "0%"
        })
          .to(
            cardInfosContainerEl
              .querySelector(".current--info")
              .querySelectorAll(".text"),
            {
              delay: 0.5,
              duration: 0.4,
              stagger: 0.1,
              opacity: 1,
              translateY: 0
            }
          )
          .to(
            [buttons.prev, buttons.next],
            {
              duration: 0.4,
              opacity: 1,
              pointerEvents: "all"
            },
            "-=0.4"
          );
      }

      const waitForImages = () => {
        const images = [...document.querySelectorAll("img")];
        const totalImages = images.length;
        let loadedImages = 0;
        const loaderEl = document.querySelector(".loader span");

        gsap.set(cardsContainerEl.children, {
          "--card-translateY-offset": "100vh"
        });
        gsap.set(
          cardInfosContainerEl
            .querySelector(".current--info")
            .querySelectorAll(".text"),
          {
            translateY: "40px",
            opacity: 0
          }
        );
        gsap.set([buttons.prev, buttons.next], {
          pointerEvents: "none",
          opacity: "0"
        });

        images.forEach(image => {
          imagesLoaded(image, instance => {
            if (instance.isComplete) {
              loadedImages++;
              let loadProgress = loadedImages / totalImages;

              gsap.to(loaderEl, {
                duration: 1,
                scaleX: loadProgress,
                backgroundColor: `hsl(${loadProgress * 120}, 100%, 50%`
              });

              if (totalImages == loadedImages) {
                gsap
                  .timeline()
                  .to(".loading__wrapper", {
                    duration: 0.8,
                    opacity: 0,
                    pointerEvents: "none"
                  })
                  .call(() => init());
              }
            }
          });
        });
      };

      waitForImages();
    },
    calcuateNumberOfOpstini(id) {
      const data = this.coordinates;
      const number = data.filter(item => {
        return item.opstinis_id === id;
      });
      return number.length;
    },
    changeTableLanguage() {
      if (this.language === 'MK' || this.language === 'МК') {
        const lang = {
          pagination: {
            goto: "до",
            itemsPerPage: " /страна",
            total: function total(_total) {
              return "Вкупно " + _total;
            }
          }
        };
        this.$veLocale.update(lang);
      } else {
        const lang = {
          pagination: {
            goto: "Deri më",
            itemsPerPage: " /faqes",
            total: function total(_total) {
              return "Total " + _total;
            }
          }
        };
        this.$veLocale.update(lang);
      }
    },
    changeSelectedTable(tableName) {
      this.typeFilter = tableName;
      this.searchInstalacija = "";
      this.searchElaborati = "";
      this.setTable(tableName);
    },
    getFormatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getTableName(id) {
      if (this.language === 'MK' || this.language === 'МК') {
        if (id === 1) {
          return "А Дозволи";
        } else if (id === 2) {
          return "Б Дозволи";
        } else if (id === 3) {
          return "Елаборати";
        } else if (id === 3) {
          return "Елаборати МЖСПП";
        }
      } else {
        if (id === 1) {
          return "A Licenca";
        } else if (id === 2) {
          return "B Licenca";
        } else if (id === 3) {
          return "Elaborate";
        } else if (id === 3) {
          return "Elaborate MMJPH";
        }
      }
    },
    getTableNameElaborati(id, type) {
      if (this.language === 'МК' || this.language === 'MK') {
        if (type) {
          return "Елаборати";
        } else {
          return "Елаборати МЖСПП";
        }
      } else {
        if (type) {
          return "Elaborate";
        } else {
          return "Elaborate MMJPH";
        }
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
    },
    pageNumberChangeSecondTable(pageIndexSecondTable) {
      this.pageIndexSecondTable = pageIndexSecondTable;
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
    },
    pageSizeChangeSecondTable(pageSizeSecondTable) {
      this.pageIndexSecondTable = 1;
      this.pageSizeSecondTable = pageSizeSecondTable;
    },
    redirectToExternalPage(url) {
      window.open(url, "_blank").focus();
    },
    redirectToExternal(data) {
      window.open(data.buttonLink, "_blank").focus();
    },
    selectTableAndScroll(tableName) {
      this.setTable(tableName);
      this.smoothScroll("podatoci");
    },
    updateNullOpstinis() {
      this.coordinatesData.b_dozvoli.map(item => {
        if (item.opstinis === null) {
          return (item.opstinis = []);
        }
      });
      this.coordinatesData.elaborati.map(item => {
        if (item.opstinis === null) {
          return (item.opstinis = []);
        }
      });
    }
  },
  mounted() {
    this.animateSlider();
    this.animateTopCard();
    this.changeTableLanguage();
    this.updateNullOpstinis();

    if (this.language === 'МК' || this.language === 'MK') {
      this.selectedOpstina = ALL
      } else {
      this.selectedOpstina = ALLAl
    }
  }
};
</script>
