export default {
  coordinatesData: {},
  inputData: {},
  language: "MK",
  loadingScreen: true,
  opstini: [],
  selectedCoordinatesType: "Сите податоци",
  selectedEndDate: "",
  selectedItem: [],
  selectedStartDate: "",
  selectedTable: "А Дозволи",
  theme: "",
  year: null
};
