export const dropDownNavMK = [
  {
    href: "podatoci",
    isLink: 0,
    name: "Елаборати Општини"
  },
  {
    href: "podatoci",
    isLink: 0,
    name: "Елаборати МЖСПП"
  }
];

export const dropDownNavAL = [
  {
    href: "podatoci",
    isLink: 0,
    name: "Komuna Elaborate"
  },
  {
    href: "podatoci",
    isLink: 0,
    name: "Elaborate MMJPH"
  }
];
