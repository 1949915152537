import client from "api-client";

export default {
  async fetchCMSData({ state, commit }) {
    return await client.fetchCMSData(state.language).then(data => {
      commit("setCMSData", data);
    });
  },
  fetchAPIData({ commit }) {
    return client.fetchAPIData().then(response => {
      commit("setAPIData", response.data);
      commit("setOpstini", response.opstini);
    });
  },
  setLanguage({ commit }, language) {
    client.fetchCMSData(language).then(data => {
      commit("setCMSData", data);
    });
    commit("setLanguage", language);
  },
  setSelectedItem({ commit }, item) {
    commit("setSelectedItem", item);
  },
  setSelectedStartDate({ commit }, date) {
    commit("setSelectedStartDate", date);
  },
  setSelectedEndDate({ commit }, date) {
    commit("setSelectedEndDate", date);
  },
  setSelectedCoordinatesType({ commit }, type) {
    commit("setSelectedCoordinatesType", type);
  },
  setTable({ commit }, tableName) {
    commit("setTable", tableName);
  },
  setTheme({ commit }) {
    commit("setTheme");
  },
  setYear({ commit }) {
    commit("setYear");
  },
  toggleTheme({ commit }) {
    commit("toggleTheme");
  }
};
