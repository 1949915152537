<template>
  <div class="flexi-container page__internal page__terms">
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.homePage.top_card"
      @button-clicked="smoothScroll"
    />

    <flexi-advanced-last-card
      v-if="inputData.homePage"
      :inputData="inputData.homePage.last_card"
      :navigationPages="inputData.navigationPages"
      :loadingForm="loading"
      @button-clicked="sendNewsEmail"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "terms",
  mounted() {
    setTimeout(() => {
      this.smoothScroll("top-card");
    }, 2000);
  },
  computed: {
    ...mapGetters(["inputData"])
  }
};
</script>

<style lang="scss">
// @import "~@schnapsterdog/flexi-vue/src/assets/scss/parts/_media-mixins";
</style>
